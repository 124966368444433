import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styled from '@emotion/styled';
import Headline5
  from '../Typography/Heading/Headline5';
import SubTitle
  from '../Typography/SubTitle';
import SubTitle2
, { Subtitle2Properties } from '../Typography/SubTitle2';
import Body2
  from '../Typography/Body/Body2';
import { List } from './index';
import breakpoints from '../Theme/breakpoints';
import Skeleton from '../SkeletonLoader/Skeleton';
import DescriptionSkeleton from '../SkeletonLoader/Default';

const ListItem = styled.li<{ highlighted?: boolean }>`
  display: flex;
  flex-flow: row wrap;
  padding: 24px 0 0 0;
  position: relative;
  ${({ highlighted }) => highlighted && `
  ::after {
    content: '';
    border-left: 3px solid var(--primary-color);
    position: absolute;
    top: 24px;
    bottom: 30px;
    left: 4px;
  }
  `}
`;

export const ImageContainer = styled.div`
  width: 60px;
  height: 60px;
  margin: 0 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${breakpoints.xssm}px) {
    width: 100px;
    height: 100px;
  }

  @media (min-width: ${breakpoints.md}px) {
    margin: 0 16px 0 16px;
  }

  /* stylelint-disable-next-line selector-type-no-unknown */
  progressive-img {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  && {
    /* stylelint-disable-next-line selector-type-no-unknown */
    progressive-img {
      --img-width: 60px;
      --img-object-fit: contain;
      --img-height: 80px;

      @media (min-width: ${breakpoints.xssm}px) {
        --img-width: 100px
      }
    }
  }
`;

const detailsContainerPropertyTypes = {
  showDivider: PropTypes.bool,
};

type DetailsContainerProperties = InferProps<
  typeof detailsContainerPropertyTypes
>;

const DetailsContainer = styled.div<DetailsContainerProperties>`
  margin: 0;
  flex: 1;
  padding: 0 8px 8px 0;
  word-break: break-word;
  border-bottom: ${({ showDivider }) => (
    showDivider ? '1px solid var(--grey-light)' : 'none'
  )};
`;

DetailsContainer.propTypes = detailsContainerPropertyTypes;

const StyledSubTitle = styled(SubTitle)`
  margin: .75rem 0;
  display: inline-block;
`;

const EBPLink = styled(StyledSubTitle)`
  a {
    color: var(--primary-text);
  }
  a:hover, a:active {
    text-decoration: underline;
  }
`;

const jobLocationPropertyTypes = {
  city: PropTypes.node,
  address: PropTypes.string,
  addressRegion: PropTypes.string,
  buildingName: PropTypes.string,
  postalCode: PropTypes.string,
};

type JobLocationProperties = InferProps<
  typeof jobListItemPropertyTypes
>;

const JobLocation = ({
  buildingName,
  address,
  city,
  addressRegion,
  postalCode,
}: JobLocationProperties) => (
  <>
    { buildingName && (
    <>
      {buildingName}
      {' '}
      |
      {' '}
    </>
    )}
    { address && (
      <>
        {address}
        {' '}
        |
        {' '}
      </>
    )}
    {city}
    { addressRegion && (
      <>
        {', '}
        {addressRegion}
      </>
    )}
    { postalCode && (
      <>
        {' '}
        {postalCode}
      </>
    )}
  </>
);

JobLocation.propTypes = jobLocationPropertyTypes;

const PublishedAtContainer = styled(
  (properties: Subtitle2Properties) => <SubTitle2 {...properties} />,
)`
  min-width: 10em;
  margin: auto 0;

  @media (min-width: ${breakpoints.sm}px) {
    text-align: right;

  }
`;

const StyledBody2 = styled(Body2)`
  margin: 0;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  -webkit-line-clamp: 5;
  /* autoprefixer: ignore next */
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: ${breakpoints.xssm}px) {
    display: block;
    text-overflow: initial;
    -webkit-line-clamp: none;
    /* autoprefixer: ignore next */
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-box-orient: initial;
    overflow: visible;
  }
`;

const DetailsFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  flex-direction: column;

  @media (min-width: ${breakpoints.sm}px) {
    flex-direction: row;
  }
`;

const Badge = styled.span`
  background-color: var(--primary-color);
  padding: 1.5px 5px;
  margin-right: 5px;
  line-height: 1;
  letter-spacing: .01em;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  white-space: nowrap;
  position: absolute;
  top: 2px;

  &:first-of-type {
    left: 0;
  }

  &:nth-of-type(n+2) {
    left: 5rem;
  }
`;

const BadgesContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  height: 17px;
  min-width: 10rem;
  margin-top: 0.25rem;

  @media (min-width: ${breakpoints.sm}px) {
    display: inline-block;
  }
`;

const StyledHeadline5 = styled(Headline5)`
  margin-right: .25rem;
`;

const StyledSkeleton = styled(Skeleton)`
  display: inline-block;
`;

const jobListItemPropertyTypes = {
  title: PropTypes.node,
  teaser: PropTypes.node,
  company: PropTypes.node,
  city: PropTypes.node,
  address: PropTypes.string,
  addressRegion: PropTypes.string,
  buildingName: PropTypes.string,
  postalCode: PropTypes.string,
  logo: PropTypes.node,
  badges: PropTypes.arrayOf(PropTypes.string),
  ebpHighlighted: PropTypes.bool,
  publishedAt: PropTypes.node,
  showDivider: PropTypes.bool,
  loadingBadges: PropTypes.bool,
  badgeLoader: PropTypes.node,
  showLoader: PropTypes.bool,
};

type JobListItemProperties = InferProps<
  typeof jobListItemPropertyTypes
> & {
  badges?: string[];
  ebpHighlighted?: boolean;
};

const JobListItem = ({
  title,
  teaser,
  company,
  city,
  address,
  addressRegion,
  buildingName,
  postalCode,
  logo,
  badges = [],
  ebpHighlighted,
  publishedAt,
  showDivider = true,
  loadingBadges = false,
  badgeLoader,
  showLoader = false,
}: JobListItemProperties) => (
  <ListItem highlighted={ebpHighlighted}>
    {showLoader ? (
      <ImageContainer>
        <Skeleton variant="rectangular" width="100%" height="100%" />
      </ImageContainer>
    ) : <ImageContainer>{logo}</ImageContainer>}
    <DetailsContainer showDivider={showDivider}>
      {showLoader ? (
        <StyledHeadline5>
          <StyledSkeleton
            variant="rectangular"
            width="50%"
          >
            &nbsp;
          </StyledSkeleton>
          <BadgesContainer>
            {!loadingBadges && badges.map((badge) => (
              <Skeleton
                key={badge}
                variant="rectangular"
                width="40%"
                height="100%"
              />
            ))}
            {badgeLoader}
          </BadgesContainer>
        </StyledHeadline5>
      ) : (
        <StyledHeadline5 tag="h2">
          {title}
          {' '}
          <BadgesContainer>
            {!loadingBadges && badges.map((badge) => (
              <Badge key={badge}>{badge}</Badge>
            ))}
            {badgeLoader}
          </BadgesContainer>
        </StyledHeadline5>
      )}
      <EBPLink tag="span">
        {showLoader ? (
          <Skeleton variant="rectangular" width="100px">&nbsp;</Skeleton>
        ) : company}
      </EBPLink>
      <StyledBody2>{showLoader ? <DescriptionSkeleton /> : teaser}</StyledBody2>
      <DetailsFooter>
        <StyledSubTitle tag="span">
          {showLoader ? (
            <Skeleton variant="rectangular" width="100px">&nbsp;</Skeleton>
          ) : (
            <JobLocation
              city={city}
              address={address}
              addressRegion={addressRegion}
              buildingName={buildingName}
              postalCode={postalCode}
            />
          )}
        </StyledSubTitle>
        <PublishedAtContainer tag="span">
          {showLoader
            ? <Skeleton variant="rectangular" width="100px">&nbsp;</Skeleton>
            : publishedAt}
        </PublishedAtContainer>
      </DetailsFooter>
    </DetailsContainer>
  </ListItem>
);

JobListItem.propTypes = jobListItemPropertyTypes;

const relatedJobListItemPropertyTypes = {
  logo: PropTypes.node,
  title: PropTypes.node,
  company: PropTypes.node,
  buildingName: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.node,
  addressRegion: PropTypes.string,
  postalCode: PropTypes.string,
  publishedAt: PropTypes.node,
};

type RelatedJobListItemProperties = InferProps<
  typeof relatedJobListItemPropertyTypes
>;

const RelatedJobListItemWrapper = styled.li`
  padding-top: 8px;
  display: flex;
  flex-flow: row wrap;
  border-top: 1px solid var(--grey-light);
`;

const RelatedJobListItemCompany = styled(SubTitle)`
  && {
    padding: 0;
    margin: 0;
  }
  display: inline-block;
  a {
    color: var(--primary-text);
  }
  a:hover, a:active {
    text-decoration: underline;
  }
`;

const RelatedJobListItemLocation = styled(SubTitle)`
  && {
    padding: 0;
    margin: 0;
  }
`;

const RelatedJobListItemFooter = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: ${breakpoints.sm}px) {
    flex-direction: row;
  }
`;

const RelatedJobListItem = ({
  title,
  company,
  city,
  address,
  addressRegion,
  buildingName,
  postalCode,
  logo,
  publishedAt,
}: RelatedJobListItemProperties) => (
  <RelatedJobListItemWrapper>
    <ImageContainer>{logo}</ImageContainer>
    <DetailsContainer>
      <StyledHeadline5 tag="h2">
        {title}
      </StyledHeadline5>
      <RelatedJobListItemCompany tag="span">
        {company}
      </RelatedJobListItemCompany>
      <RelatedJobListItemFooter>
        <RelatedJobListItemLocation tag="span">
          <JobLocation
            city={city}
            address={address}
            addressRegion={addressRegion}
            buildingName={buildingName}
            postalCode={postalCode}
          />
        </RelatedJobListItemLocation>
        <PublishedAtContainer tag="span">{publishedAt}</PublishedAtContainer>
      </RelatedJobListItemFooter>
    </DetailsContainer>
  </RelatedJobListItemWrapper>
);

RelatedJobListItem.propTypes = relatedJobListItemPropertyTypes;

const jobListPropertyTypes = {
  highlighted: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};

type JobListProperties = InferProps<
  typeof jobListPropertyTypes
> & {
  className?: string;
};

const JobList = styled(
  ({ children, className }: JobListProperties) => (
    <List className={className}>{children}</List>
  ),
)<JobListProperties>`
  && {
    padding: 0;
  }
  background-color: ${
  ({ highlighted = false }) => (highlighted ? '#f5f5f5' : 'transparent')};
`;

JobList.propTypes = jobListPropertyTypes;

const JobListMessage = styled(Body2)`
  text-align: center;
  margin: 24px 0;
  padding: 8px;
  background-color: var(--grey-light);

  .keywords {
    color: var(--primary-color);
    font-weight: 600;
  }
`;

JobListMessage.defaultProps = {
  tag: 'div',
};

const CountContainer = styled.div`
  && {
    text-align: center;
    margin: 0 24px;
    color: var(--black-medium);
    font-family: var(--font-secondary);
    font-size: var(--size-h3);
    line-height: var(--size-h4);
  }
`;

const jobsCountPropertyTypes = {
  children: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

type JobsCountProperties = InferProps<typeof jobsCountPropertyTypes>;

const JobsCount = ({ children }: JobsCountProperties) => (
  <CountContainer>
    {children}
  </CountContainer>
);

JobsCount.propTypes = jobsCountPropertyTypes;

export {
  JobList, JobListItem,
  JobListMessage, JobsCount,
  RelatedJobListItem,
};
